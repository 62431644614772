<template>
  <el-dialog
    :visible="visible"
    :title="titleType"
    width="50%"
    :show-close="false"
    @close="handleCancel"
  >
    <el-form :model="info" :inline="true" small="size">
      <template v-if="attach.type === 'patientInfo'">
        <h4 class="title">患者信息</h4>
        <el-row>
          <el-col :span="12">
            <el-form-item label="患者姓名:">
              {{ info.patient.name }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="患者性别:">
              <span>{{ sexDic[info.patient.gender] }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年龄:">
              {{ info.patient.age }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式:">
              {{ info.patient.mobile }}
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="info.patient.visitingHospital">
          <el-row>
            <el-col :span="12">
              <el-form-item label="线下就诊医院:">
                {{ info.patient.visitingHospital }}
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <h4 class="title">咨询内容</h4>
        <div>{{ info.patient.description }}</div>
      </template>
      <template v-if="attach.type === 'consultationSummary'">
        <h4 class="title">主诉</h4>
        <div>{{ info.chiefComplaint }}</div>
        <h4 class="title">现病史</h4>
        <div>{{ info.presentIllnessHistory }}</div>
        <h4 class="title">既往史</h4>
        <div>{{ info.pastHistory }}</div>
        <h4 class="title">诊断</h4>
        <div>{{ info.diagnosisName }}</div>
        <h4 class="title">处理建议</h4>
        <div>
          {{ info.handlingSuggestions }}
        </div>
      </template>
      <template v-if="attach.type === 'heartLoopInvitation'">
        <span v-if="info.eventType === 'initiateInvitation'">{{
          info.patientContent
        }}</span>
        <span v-else>{{ info.doctorContent }}</span>
      </template>
      <template v-if="attach.type === 'prescription'">
        <h4 class="title">XX健康医院</h4>
        <el-form-item label="处方号:">
          {{ info.medicals[0].prescriptionId }}
        </el-form-item>
        <el-form-item label="时间:">
          {{ getformat(info.createTime) }}
        </el-form-item>
        <el-form-item label="姓名:">
          {{ patientInfo.patientName }}
        </el-form-item>
        <el-form-item label="性别:">
          {{ sexDic[patientInfo.patientSex] }}
        </el-form-item>
        <el-form-item label="年龄:">
          {{ patientInfo.patientAge }}
        </el-form-item>
        <h4 class="title">诊断</h4>
        <div>{{ info.diagnosisName }}</div>
        <div class="title">处方药品信息</div>
        <div style="font-weight: bold; font-size: 20px; margin-left: 50px">
          Rp
        </div>
        <div class="drugList">
          <div v-for="item in info.medicals" :key="item.id">
            <div style="margin-left: 50px; margin-bottom: 10px; display: flex">
              <div class="medical">
                {{ item.medicalName }}
              </div>
              <div class="medical">
                {{ item.specs }}
              </div>
              <div class="medical">
                {{ item.specsPacking }}
              </div>
              <span>{{ item.quantity }}</span>
            </div>
            <el-form-item label="用法用量:" style="margin-left: 35px">
              <span class="medical">{{ item.medicalUsage }}</span>
              <span class="medical">{{ item.frequency }}</span>
              <span class="medical"
                >每次{{ item.consumption }}{{ item.minUseUnit }}</span
              >
              <span class="medical">连用{{ item.medicationDays }}天</span>
              <span class="medical">{{ item.entrust }}</span>
            </el-form-item>
          </div>
        </div>
        <div class="imgsty">
          <el-form-item label="医师签名:">
            <img
              v-if="info.doctorTrustSignatureUrl"
              style="width: 100px; height: 50px"
              :src="info.doctorTrustSignatureUrl"
            />
          </el-form-item>
          <template v-if="info.auditTrustSignatureUrl">
            <el-form-item label="药师签名:">
              <img
                style="width: 100px; height: 50px"
                :src="info.auditTrustSignatureUrl"
              />
            </el-form-item>
          </template>
        </div>
      </template>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="handleCancel"> 返回 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sexDic } from '@/utils/component/publicList';
import moment from 'moment';
export default {
  name: 'HistoryDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    titleType: {
      type: String,
      default: '',
    },
    attach: {
      type: Object,
      default: () => {},
    },
    patientInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sexDic: sexDic,
      fileList: [], // 导入文件列表
      info: JSON.parse(JSON.stringify(this.attach.params)),
    };
  },
  computed: {},
  methods: {
    // 取消
    handleCancel() {
      this.$emit('cancel');
    },
    getformat(date) {
      var date1 = new Date(date);
      var b = moment(date1).format('YYYY-MM-DD');
      return b;
    },
  },
};
</script>

<style scoped>
.upload-icon {
  margin: 30px;
  font-size: 50px;
  color: #1890ff;
}
.medical {
  margin-right: 20px;
}
.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #e8e8e8;
}
</style>

<style>
.export-list {
  float: right;
  padding: 7px 15px 7px 15px;
  margin-top: 10px;
  color: #fff;
  background: #1890ff;
  border: none;
  border-radius: 5px;
}
.imgsty {
  /* margin-left:50px; */
  display: flex;
  align-items: center;
}
</style>
